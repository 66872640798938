import $ from 'jquery';
import { DateTime, Interval } from 'luxon';

(function () {
    let dateTime = DateTime.now(),
        startDate = DateTime.fromISO(dateTime.year + '-04-14'),
        endReadyDate = DateTime.fromISO(dateTime.year + '-05-01'),
        endDate = DateTime.fromISO(dateTime.year + '-10-01'),
        intervalCurrent = Interval.fromDateTimes(startDate, endDate),
        intervalNext = Interval.fromDateTimes(startDate.plus({ years: 1 }), endDate.plus({ years: 1 })),
        isAfter = intervalCurrent.isBefore(dateTime.minus({ days: 1 })),
        isBefore = intervalNext.isAfter(dateTime),
        intervalReady = Interval.fromDateTimes(startDate, endReadyDate);

    //console.log(startDate);
    //console.log(endDate);
    //console.log(intervalCurrent);
    //console.log(intervalNext);

    if ($('#winterModal').length && isAfter && isBefore) {
        $('#winterModal').modal('show');
    }

    if($('#readyModal').length && intervalReady.contains(dateTime)) {
        $('#readyModal').modal('show');
    }
})($);
