import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from 'jquery-datepicker/i18n/jquery.ui.datepicker-de';
import { DateTime, Interval } from 'luxon';
//import Interval from 'luxon/src/interval.js'

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
    faChevronSquareLeft,
    faChevronSquareRight
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faChevronSquareLeft,
    faChevronSquareRight
);

dom.watch();

// Just pass your jquery instance and you're done
datepickerFactory($);
datepickerJAFactory($);

let timepicker = {
    create: function (date, type) {
        axios.get('/api/' + type + '/slots/' + date.toISO())
            .then(res => {
                this.html(res.data, date);
            }).catch(err => {
                console.log(err)
            })
    },
    html: function (slots, date) {

        let html,
            day = date.toLocaleString({ day: 'numeric' }),
            weekday = date.toLocaleString({ weekday: 'short' });

        html = '<div class="row app-timepicker-row">';

        html += '<div class="col-auto app-timepicker-date">';
        html += '<span class="app-timepicker-date-day">' + day + '</span> <span class="app-timepicker-date-weekday">' + weekday + '</span>';
        html += '</div>';

        html += '<div class="col"><div class="app-timepicker-grid">';
        for (var stamp in slots) {
            let disabled = (slots[stamp].open === false) ? ' disabled' : '';
            html += '<div class="app-timepicker-item"><a href="#" class="app-timepicker-btn btn' + disabled + '" data-date=' + stamp + '>' + slots[stamp].time + '</a></div>';
        }
        html += '</div></div>';


        html += '</div>';
        $('.app-timepicker').html(html);
    }
}


$(function () {
    let $datepicker = $('#app-datepicker');

    if ($datepicker.length) {

        $.datepicker.regional['de'];

        let type = $datepicker.data('type'),
            startDate = DateTime.fromISO(DateTime.now().year + '-04-14'),
            startDateCorrected = (startDate > DateTime.now()) ? startDate : DateTime.now(),
            endDate = DateTime.fromISO(DateTime.now().year + '-10-01'),
            intervalCurrent = Interval.fromDateTimes(startDate, endDate),
            intervalNext = Interval.fromDateTimes(startDate.plus({ years: 1 }), endDate.plus({ years: 1 }));

        $datepicker.datepicker({
            minDate: startDateCorrected.toJSDate(),
            maxDate: endDate.plus({ years: 1 }).toJSDate(),
            prevText: '<i class="fas fa-chevron-square-left"></i>',
            nextText: '<i class="fas fa-chevron-square-right"></i>',
            beforeShow: function () {
                console.log('beforeShow');
            },
            beforeShowDay: function (date) {
                let dateTime = DateTime.fromJSDate(date),
                    isAfter = intervalCurrent.isBefore(dateTime.minus({ days: 1 })),
                    isBefore = intervalNext.isAfter(dateTime);

                return [(isAfter && isBefore) ? false : true];
            },
            onChangeMonthYear: function () {
                console.log('onChangeMonthYear');
            },
            onSelect: function (date) {
                date = DateTime.fromFormat(date, 'dd.MM.yyyy').setLocale('de');
                timepicker.create(date, type);
                $('.form-date [type="submit"]').addClass('disabled');
            }
        });

        let currentDate = $datepicker.datepicker("getDate");
        timepicker.create(DateTime.fromJSDate(currentDate).setLocale('de'), type);

        $(document).on('click', '.app-timepicker-btn', function (e) {
            e.preventDefault();
            let $this = $(this);

            $(document).find('.app-timepicker-btn').removeClass('active');
            $this.addClass('active');

            $('[name="date"]').val($this.data('date'));

            $('.form-date [type="submit"]').removeClass('disabled');
        });

        $('.form-date [type="submit"]').on('click', function (e) {
            if ($(this).hasClass('disabled')) {
                e.preventDefault();
            }
        });

    }
});
